<template>
  <div id="app">
    <router-view></router-view>
    <play-bar :current-drama="currentDrama" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PlayBar from '@/components/PlayBar.vue'

export default {
  name: 'App',
  components: {
    PlayBar
  },
  computed: {
    ...mapState(['currentDrama'])
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  min-height: 100vh;
  background: linear-gradient(to bottom right, $background-color, $secondary-background-color);
  padding-bottom: 50px; /* 为播放条预留空间 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
