export const dramaList = [
  {
    id: 1,
    title: '丑妻逆袭',
    image: 'https://picsum.photos/400/300?random=122',
    description: '这是短剧《丑妻逆袭》的描述。',
    audioUrl: require('@/assets/audio/丑妻逆袭.mp3'),
    author: '未知',
    narrator: '未知'
  },
  {
    id: 2,
    title: '疯狂赘婿',
    image: 'https://picsum.photos/400/300?random=444',
    description: '这是短剧《疯狂赘婿》的描述。',
    audioUrl: require('@/assets/audio/疯狂赘婿.mp3'),
    author: '未知',
    narrator: '未知'
  },
  {
    id: 3,
    title: '先婚后爱之亡夫归来',
    image: 'https://picsum.photos/400/300?random=1',
    description: '这是短剧《先婚后爱之亡夫归来》的描述。',
    audioUrl: require('@/assets/audio/短剧《先婚后爱之亡夫归来》.wav'),
    author: '未知',
    narrator: '未知'
  },
  {
    id: 4,
    title: '先婚后爱之亡夫归来 2',
    image: 'https://picsum.photos/400/300?random=2',
    description: '这是短剧《先婚后爱之亡夫归来》2的描述。',
    audioUrl: require('@/assets/audio/短剧《先婚后爱之亡夫归来》2.wav'),
    author: '未知',
    narrator: '未知'
  },
  {
    id: 5,
    title: '逃婚总裁爱上我',
    image: 'https://picsum.photos/400/300?random=3',
    description: '这是短剧《逃婚总裁爱上我》的描述。',
    audioUrl: require('@/assets/audio/逃婚总裁爱上我.wav'),
    author: '未知',
    narrator: '未知'
  }
]; 