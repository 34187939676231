import Vue from 'vue'
import Vuex from 'vuex'
import { dramaList } from './data'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dramaList: dramaList,
    currentDrama: null,
    loading: false,
    categories: [
      { id: 1, name: '悬疑' },
      { id: 2, name: '爱情' },
      { id: 3, name: '科幻' },
      { id: 4, name: '历史' },
      { id: 5, name: '冒险' }
    ]
  },
  mutations: {
    SET_DRAMA_LIST(state, list) {
      state.dramaList = list
    },
    SET_CURRENT_DRAMA(state, drama) {
      state.currentDrama = drama
    },
    SET_LOADING(state, status) {
      state.loading = status
    }
  },
  actions: {
    async fetchDramaList({ commit }) {
      commit('SET_LOADING', true)
      try {
        // TODO: 替换为实际的API调用
        const response = await Promise.resolve([
          {
            id: 1,
            title: '谜案追凶',
            cover: 'https://example.com/cover1.jpg',
            description: '一部扣人心弦的悬疑短剧',
            author: '张三',
            narrator: '李四',
            playCount: 10000,
            rating: 4.5
          }
        ])
        commit('SET_DRAMA_LIST', response)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchDramaDetail({ commit }, id) {
      commit('SET_LOADING', true)
      try {
        // TODO: 替换为实际的API调用
        const response = await Promise.resolve({
          id: id,
          title: '谜案追凶',
          cover: 'https://example.com/cover1.jpg',
          description: '一部扣人心弦的悬疑短剧',
          author: '张三',
          narrator: '李四',
          playCount: 10000,
          rating: 4.5,
          chapters: [
            { id: 1, title: '第一集', duration: '20:00' },
            { id: 2, title: '第二集', duration: '22:00' }
          ]
        })
        commit('SET_CURRENT_DRAMA', response)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
})
