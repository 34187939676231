<template>
  <div class="play-bar">
    <h2>{{ currentDrama.title }}</h2>
    <audio controls :src="currentDrama.audioUrl" v-if="currentDrama.audioUrl">
      您的浏览器不支持音频播放。
    </audio>
  </div>
</template>

<script>
export default {
  name: 'PlayBar',
  props: {
    currentDrama: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
.play-bar {
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
}
</style>
